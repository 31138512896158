<template>
  <div class="pageContent">
    <searchHeader />
    <div class="mall">
      <div class="category">
        <div class="firstCategory">
          <span
            @click="changeCateTwo(null, null)"
            class="item"
            :class="activeCateId === null ? 'active' : ''"
            >全部</span
          >
          <span
            @click="changeCateTwo(item.classId, item.className)"
            class="item"
            :class="activeCateId === item.classId ? 'active' : ''"
            v-for="item in varietiesCateList"
            :key="item.classId"
            >{{ item.className }}</span
          >
        </div>
        <div v-if="isFirst">
          <div
            class="categoryList"
            v-for="(items, index) of twocategoryOptions"
            :key="index"
          >
            <div class="categoryTitle">{{ items.className }}</div>
            <div class="categoryContent">
              <span
                @click="
                  changeThreeCate(item.classId, item.className, items.className)
                "
                class="item"
                :class="activeCateId === item.classId ? 'active' : ''"
                v-for="item in items.children"
                :key="item.classId"
                >{{ item.className }}</span
              >
            </div>
          </div>
        </div>
        <div v-if="!isFirst">
          <div style="line-height:30px;color:#666;">
            当前位置：竞价交易>{{ firstClassName }}>{{ twoClassName }}>{{
              threeClassName
            }}
          </div>
          <div
            class="categoryList"
            v-for="(items, index) of treecategoryOptions"
            :key="index"
          >
            <div class="categoryTitle">{{ items.name }}</div>
            <div class="categoryContent">
              <span
                @click="changeCateTree(item, index)"
                class="item"
                :class="item.activeCateId ? 'active' : ''"
                v-for="item in items.content"
                :key="item.id"
                >{{ item.value }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="search" v-for="(skuItem, index) in sku" :key="index">
        <span class="activeSearchItem">{{ skuItem.title }}</span>
        <span class="searchItem" @click="selectItem(index, -1)">
          <span :class="skuItem.select == '' ? 'redBox' : ''"
            >&nbsp;全部&nbsp;</span
          >
        </span>
        <span
          class="searchItem"
          v-for="(skuItemStr, indexs) in skuItem.message"
          :key="indexs"
          @click="selectItem(index, indexs)"
        >
          <span :class="skuItem.select == skuItemStr ? 'redBox' : ''"
            >&nbsp;{{ skuItemStr }}&nbsp;</span
          >
        </span>
      </div>
      <!-- <div class="sort">
        <span class="sortItem active">综合</span>
        <span class="sortItem">
          价格
          <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
        </span>
        <span class="sortItem">
          销量
          <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
        </span>
      </div> -->
      <div class="productionList">
        <template>
          <el-table
            :data="goods"
            style="width: 100%;cursor:pointer;"
            stripe
            @cell-click="TableRowClick"
          >
            <el-table-column
              prop="hangResourcesId"
              label="竞拍号"
              width="70"
            ></el-table-column>
            <el-table-column label="商品分类">
              <template slot-scope="scope">
                {{ scope.row.className }}
              </template>
            </el-table-column>
            <el-table-column label="交易商">
              <template slot-scope="scope">
                {{ scope.row.hangEnterpriseName }}
              </template>
            </el-table-column>
            <el-table-column prop="startPrice" label="起拍总价(元)">
              <template slot-scope="scope">
                ¥{{ scope.row.startPrice }}
              </template>
            </el-table-column>
            <el-table-column label="竞价时间" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.startTime | DateFormate }} 至</p>
                <p>{{ scope.row.endTime | DateFormate }}</p>
              </template>
            </el-table-column>
            <el-table-column label="竞价类型">
              <template slot-scope="scope">
                {{ scope.row.hangResourcesType | biddingTypeFormate }}
              </template>
            </el-table-column>
            <el-table-column label="挂单方式">
              <template slot-scope="scope">
                {{ scope.row.hangResourcesType | typeFormate }}
              </template>
            </el-table-column>
            <el-table-column prop="sname" label="交收仓库"></el-table-column>
            <el-table-column label="状态" width="90">
              <template slot-scope="scope">
                {{ scope.row.style }}
              </template>
            </el-table-column>
            <el-table-column
              prop="enNum"
              label="报名人数"
              width="90"
            ></el-table-column>
            <el-table-column label="" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  @click.native.prevent="
                    goProductionDetails(scope.$index, scope.row)
                  "
                  v-if="
                    scope.row.style != '流拍' &&
                      scope.row.style != '已结束' &&
                      scope.row.signUpStyle == '未报名'
                  "
                  >报名</el-button
                >
                <el-button
                  type="danger"
                  disabled
                  v-if="
                    scope.row.style != '已结束' &&
                      scope.row.signUpStyle == '已报名'
                  "
                  >已报名</el-button
                >
                <el-button
                  type="info"
                  disabled
                  v-if="scope.row.style == '已结束'"
                  >结束</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.current"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.totalPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 报名 -->
    <el-dialog
      v-dialogDrag
      title="确定报名"
      :close-on-click-modal="false"
      :visible.sync="SignUp.isGoSignUp"
      width="540px"
    >
      <el-row>
        <el-col :span="24"
          >当前起拍总价为{{ selectGood.startPrice }},基准总价为{{
            selectGood.jzPrice
          }},需要履约订金{{ Number(selectGood.marginPrice).toFixed(2) }}</el-col
        >
      </el-row>
      <el-form ref="dialogForm" :rules="biddingRules" :model="dialogForm">
        <el-form-item label="短信验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input
                    v-model="dialogForm.code"
                    placeholder="短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    class="width-100"
                    :disabled="validBtnDisabled"
                    @click="sendValidNo()"
                    >{{ validButtonText }}</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="摘单类型"
          prop="type"
          v-if="selectGood.hangResourcesType == 'Q'"
        >
          <el-select class="width-100" v-model="dialogForm.type">
            <el-option
              v-for="items in typeOptions"
              :key="items.value"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="持仓ID"
          prop="holdId"
          v-if="selectGood.hangResourcesType == 'Q'"
        >
          <el-select class="width-100" v-model="dialogForm.holdId">
            <el-option
              v-for="items in holdCategoryOptions"
              :key="items.holdId"
              :label="
                items.holdId +
                  '-' +
                  items.className +
                  (items.manufacturer ? '-' + items.manufacturer : '') +
                  (items.placeOrigin ? '-' + items.placeOrigin : '') +
                  (items.brand ? '-' + items.brand : '') +
                  (items.holdWeight
                    ? '-' +
                      (Number(items.holdWeight) -
                        Number(items.frozenHoldWeight)) +
                      items.unit
                    : '')
              "
              :value="items.holdId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资金密码" prop="password">
          <el-input
            v-model="dialogForm.password"
            type="password"
            placeholder="资金密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFrom('dialogForm')">取 消</el-button>
        <el-button
          type="primary"
          :loading="SignUp.loading"
          @click="goSignUp('dialogForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import searchHeader from "../base/header/searchHeader";
import md5 from "js-md5";
export default {
  components: {
    searchHeader
  },
  data() {
    var checkRequire = (rule, value, callback) => {
      const that = this;
      if (this.selectGood.hangResourcesType == "Q" && value == "") {
        return callback(new Error("请先选择持仓ID或摘单类型"));
      } else {
        callback();
      }
    };
    return {
      rowData: null,
      sku: [
        // {'title':'品种','message': ['稀土氧化物', '稀土金属产品', '稀土合金', '稀土原料', '抛光材料'],'select':''},
        // {'title':'品名','message': [],'select':''},
        {
          title: "状态",
          message: ["公示中", "竞拍中", "已结束", "流拍"],
          select: ""
        },
        { title: "类型", message: ["竞买", "竞卖"], select: "" }
        // {'title':'品级','message': ["国标","非国标"],'select':''}
      ],
      className: "",
      typeOptions: [
        // SO：卖履约订金摘SA：卖仓单摘
        { value: "SO", label: "履约订金摘" },
        { value: "SA", label: "现货摘" }
      ],
      categoryOptions: [], // 品种+品名
      holdCategoryOptions: [],
      sku1Data: [], //全部二级标签
      gradeIdOptions: [], // 品级
      selectSku: [-1, -1, -1, -1, -1],
      goods: [{ style: "1" }],
      pages: {
        pageSize: 10,
        current: 1,
        totalPage: null
      },
      selectGood: {
        style: "",
        goods1: "",
        goods2: "",
        goods3: "",
        goods4: "",
        goods5: "",
        goods6: "",
        goods7: "",
        goods8: "",
        goods9: ""
      },
      SignUp: {
        isGoSignUp: false,
        loading: false
      },
      ruleForm: {
        tel: "",
        passWord: "",
        tupian: ""
      },
      labelPosition: "right",
      isFirst: true,
      twocategoryOptions: [],
      treecategoryOptions: [],
      rules: {
        passWord: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ],
        tupian: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur"
          }
        ]
      },
      captchaData: "",
      right: {
        biddingSignup: false
      },
      validButtonText: "发送验证码",
      dialogForm: {
        code: null,
        password: null,
        holdId: null,
        type: null
      },
      activeCateId: null,
      varietiesCateList: [],
      biddingRules: {
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入资金密码", trigger: "blur" }
        ],
        holdId: [
          { required: false, message: "请选择持仓ID", trigger: "blur" },
          { validator: checkRequire, trigger: "blur" }
        ],
        type: [
          { required: false, message: "请选择摘单类型", trigger: "blur" },
          { validator: checkRequire, trigger: "blur" }
        ]
      },
      validBtnDisabled: false,
      spescInfo: [],
      firstClassName: "", // 第一级
      twoClassName: "", // 第二级
      threeClassName: "" // 第二级
    };
  },
  computed: {
    ...mapGetters([
      "sessionInfoGetter",
      "userTypeGetter",
      "selectFirmApplyCommodityGetter",
      "getMarginRateGetter"
    ]), //selectFirmApplyCommodityGetter 已报名竞价信息
    signUpPrice() {
      if (this.selectGood && this.selectGood.goods4) {
        return (Number(this.selectGood.goods4) * 0.3).toFixed(2);
      }
      return 0.0;
    }
  },
  mounted() {
    this.getDatas();
    this.getQueryVarietiesCate();
    // this.getGradeIdOptions()
    // this.getCommodityCategory()
    // this.getRights()
  },
  watch: {
    "dialogForm.type": {
      handler(val, oldVal) {
        const that = this;
        if (that.selectGood.hangResourcesType == "Q") {
          this.getCommodityCategoryss(val);
        }
      }
    }
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.biddingSignup = this.isHasRight("tc-trade-auction-sign");
          if (!this.right.biddingSignup) {
            this.$EL_MESSAGE("您无此权限");
            return;
          }
          this.SignUp.isGoSignUp = true;

          this.VerificationCode();
        }
      });
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    // 第二级
    changeCateTwo(classId, ClassName) {
      if (classId == null) {
        this.className = null;
        this.spescInfo = [];
        this.activeCateId = null;
        this.isFirst = true;
        this.twocategoryOptions = [];
        this.getDatas();
        return;
      }
      this.firstClassName = ClassName;
      this.isFirst = true;
      this.activeCateId = classId;
      protocolFwd.param_getChildClassById.param.classId = classId;
      http.getRes(protocolFwd.param_getChildClassById).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.twocategoryOptions = value;
          console.log(this.twocategoryOptions);
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 点击属性
    changeCateTree(value, index) {
      let that = this;
      for (let j = 0; j < that.treecategoryOptions[index].content.length; j++) {
        that.treecategoryOptions[index].content[j].activeCateId = false;
      }
      value.activeCateId = true;
      let contentArray = [];
      for (let i = 0; i < that.treecategoryOptions.length; i++) {
        for (let k = 0; k < that.treecategoryOptions[i].content.length; k++) {
          let objKey = that.treecategoryOptions[i];
          let contentObj = that.treecategoryOptions[i].content[k];
          if (contentObj.activeCateId && contentObj.value != "全部") {
            let obj = {};
            obj[objKey.name] = contentObj.value;
            contentArray.push(obj);
          }
        }
      }
      if (contentArray.length == 0) {
        this.spescInfo = [];
      } else {
        let reg = /\[\{+/g;
        let reg1 = /\}\]+/g;
        let reg2 = /\}+/g;
        let reg3 = /\{+/g;
        let reg4 = /\:+/g;
        let newArray = JSON.stringify(contentArray).replace(reg, "");
        newArray = newArray.replace(reg1, "");
        newArray = newArray.replace(reg2, "");
        newArray = newArray.replace(reg3, "");
        newArray = newArray.replace(reg4, ": ");
        this.spescInfo = newArray;
      }

      this.getDatas();
    },
    // 属性
    changeThreeCate(classId, className, twoClassName) {
      this.className = classId;
      this.getDatas();
      this.threeClassName = className;
      this.twoClassName = twoClassName;
      protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = classId;
      http.postFront(protocolFwd.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isFirst = false;
          let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate);

          for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
            let obj = { id: 0, value: "全部", activeCateId: true };
            for (let j = 0; j < goodsGroupCommonSpec[i].content.length; j++) {
              goodsGroupCommonSpec[i].content[j].activeCateId = false;
            }
            goodsGroupCommonSpec[i].content.unshift(obj);
          }
          this.treecategoryOptions = goodsGroupCommonSpec;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getCommodityCategoryss(type) {
      // 判断是什么类型如果是求购单不用选择类型，如果是摘单需要传参
      const that = this;
      protocolFwd.param_queryHold.param.page = 0;
      protocolFwd.param_queryHold.param.size = 10000;
      if (type == "SO") {
        //不需要选择摘单类型
        protocolFwd.param_queryHold.param.holdType = ["Y"];
      } else if (type == "SA") {
        protocolFwd.param_queryHold.param.holdType = ["X", "B"];
      } else {
        protocolFwd.param_queryHold.param.holdType = null;
      }
      protocolFwd.param_queryHold.param.sellStatus = "T";
      protocolFwd.param_queryHold.param.auditHoldStatus = "T";
      protocolFwd.param_queryHold.param.specificationsId = null;
      protocolFwd.param_queryHold.param.holdStatus = null;
      protocolFwd.param_queryHold.param.specificationsName = null;
      protocolFwd.param_queryHold.param.commodityId = this.commodityId;
      protocolFwd.param_queryHold.param.unitId = this.rowData.unitId;
      protocolFwd.param_queryHold.param.specificationsName = this.rowData.specificationsName;
      protocolFwd.param_queryHold.param.storageId = this.rowData.storageId;
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.holdCategoryOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 第一级
    getQueryVarietiesCate() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.varietiesCateList = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    // 商品品种+品名
    getCommodityCategory() {
      http.postFront(protocolFwd.param_getCommodityCategory).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value;
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = [];
          this.$EL_MESSAGE(message);
        }
        console.log(this.categoryOptions);
        // 原始数据覆盖
        let arr = [];
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          arr.push(element.Value);
        }
        this.sku[0].message = arr;

        let arr1 = [];
        for (let i = 0; i < this.categoryOptions.length; i++) {
          const element = this.categoryOptions[i];
          for (let j = 0; j < element["children"].length; j++) {
            const nameObj = element["children"][j];
            arr1.push(nameObj.Value);
          }
        }
        this.sku[1].message = arr1;
        this.sku1Data = arr1;
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element["children"];
          } else {
            this.clearChildren(element.children);
          }
        }
      });
      return Arr;
    },
    // 品级
    getGradeIdOptions() {
      http.postFront(protocolFwd.param_getCommodityGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.gradeIdOptions = value;
          let arr = [];
          for (let i = 0; i < value.length; i++) {
            const element = value[i];
            arr.push(element.value);
          }
          console.log(arr);
          this.sku[4].message = arr;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // this.sku[3].message = ['a','b','c']
    },
    // 点击获取验证码
    VerificationCode: function() {
      // http.getRes(protocol.param_getCaptcha).then(response => {
      //   if (response.data) {
      //     //console.log(response)
      //     this.captchaData =
      //       "data:image/png;base64," + response.data.value.data;
      //     // 图片校验时的参数
      //     protocol.param_validImgCaptcha.param.capid = response.data.value.id;
      //   } else {
      //     this.$EL_MESSAGE(response.data);
      //   }
      // });
    },
    getDatas() {
      let param = {};
      param = {
        page: this.pages.current - 1,
        size: this.pages.pageSize,
        // auditStatus:'T',
        sort: [{ property: "hangCreateTime", direction: "DESC" }],
        specs: this.spescInfo.length != 0 ? this.spescInfo : null,
        commodityId: this.className,
        sourceId: "J"
      };
      // '公示中', '竞拍中', '已结束', '流拍'
      // isFlow", value = "是否流拍：1-流拍，0-未流拍",
      // "isFinish", value = "0:公示中 1：竞拍中 2：已结束",
      if (this.sku[0].select && this.sku[0].select == "流拍") {
        param.isFlow = ["1"];
        param.isFinish = ["0", "1", "2"];
      } else if (this.sku[0].select && this.sku[0].select == "公示中") {
        param.isFinish = ["0"];
        param.isFlow = ["0"];
      } else if (this.sku[0].select && this.sku[0].select == "竞拍中") {
        param.isFinish = ["1"];
        param.isFlow = ["0"];
      } else if (this.sku[0].select && this.sku[0].select == "已结束") {
        param.isFinish = ["2"];
        param.isFlow = ["0"];
      } else {
        param.isFinish = ["0", "1", "2"];
        param.isFlow = ["0", "1"];
      }
      if (this.sku[1].select && this.sku[1].select == "竞买") {
        param.hangResourceType = ["X", "B"];
      } else if (this.sku[1].select && this.sku[1].select == "竞卖") {
        param.hangResourceType = ["Q"];
      } else {
        param.hangResourceType = ["X", "B", "Q"];
      }
      protocolFwd.param_queryActionResourceScreening.param = param;
      http
        .postFront(protocolFwd.param_queryActionResourceScreening)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            let arr = [];
            let nowTime = Number(new Date());
            for (let i = 0; i < value.content.length; i++) {
              let element = value.content[i];
              // let startTime = element.startTime? Number(element.startTime):0
              // let endTime = element.endTime? Number(element.endTime):0
              // let showTime = element.showTime? Number(element.showTime):0
              // if (nowTime<showTime) {
              //   element['style'] = '未开始'
              // }
              // else if (nowTime>=showTime&&nowTime<startTime) {
              //   element['style'] = '公示中'
              // } else if (nowTime>=startTime&&nowTime<endTime) {
              //   element['style'] = '竞拍中'
              // } else if (nowTime>=endTime) {
              //   element['style'] = '已结束'
              // }// isFlow", value = "是否流拍：1-流拍，0-未流拍",
              // "isFinish", value = "0:公示中 1：竞拍中 2：已结束",
              if (element.isFinish == 0 && element.isFlow == 0) {
                element["style"] = "公示中";
              } else if (element.isFinish == 1 && element.isFlow == 0) {
                element["style"] = "竞拍中";
              } else if (element.isFinish == 2 && element.isFlow == 0) {
                element["style"] = "已结束";
              } else if (element.isFlow == 1) {
                element["style"] = "流拍";
              }
              element["signUpStyle"] = "未报名";
              for (
                let j = 0;
                j < this.selectFirmApplyCommodityGetter.length;
                j++
              ) {
                const selectCommodity = this.selectFirmApplyCommodityGetter[j];
                if (
                  selectCommodity.hangResourcesId == element.hangResourcesId
                ) {
                  element["signUpStyle"] = "已报名";
                }
              }
              arr.push(element);
            }
            this.goods = arr;
            this.pages.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    selectItem(index, indexs) {
      console.log(index, indexs);
      // ui
      this.selectSku[Number(index)] = Number(indexs);

      if (indexs == -1) {
        this.sku[Number(index)].select = "";
      } else {
        this.sku[Number(index)].select = this.sku[Number(index)].message[
          Number(indexs)
        ];
      }

      // // data -> ui
      // if (index=='0'&&indexs!=-1) {
      //   this.sku[1]['select'] = ''
      //   let arr1 = []
      //   for (let i = 0; i < this.categoryOptions[indexs]['children'].length; i++) {
      //     const element = this.categoryOptions[indexs]['children'][i];
      //     arr1.push(element.Value)
      //   }
      //   this.sku[1].message = arr1
      // }
      // if (index=='0'&&indexs==-1) {
      //   this.sku[1]['select'] = ''
      //   this.sku[1].message = this.sku1Data
      // }
      // console.log(this.sku)
      // data request
      this.pages = {
        pageSize: 10,
        current: 1,
        totalPage: null
      };
      this.getDatas();
    },
    goProductionDetails(index, mallGood) {
      console.log(mallGood);
      this.rowData = mallGood;
      this.commodityId = mallGood.commodityId;
      if (
        sessionStorage.getItem("sessionStr") &&
        sessionStorage.getItem("userId")
      ) {
        if (
          sessionStorage.getItem("firmId") == mallGood.hangFirmId ||
          sessionStorage.getItem("firmId") == mallGood.pickFirmId
        ) {
          this.$EL_MESSAGE("您不允许报名自己的竞价单");
          return;
        }
        this.getRights();
        this.selectGood = mallGood;
        this.selectGood.jzPrice = util.floatMul(
          mallGood.price,
          mallGood.hangWeight
        );
        this.selectGood.marginPrice = util.floatMul(
          this.selectGood.jzPrice,
          this.getMarginRateGetter.marginRate
        );
        // if(mallGood.hangResourcesType=='Q'){
        //   this.getCommodityCategoryss()
        // }
        // if(mallGood.style == 1){ //去报名

        // this.$store.commit('setSelectMallGood', mallGood)
        // this.$router.push("/mall/productionDetails");
      } else {
        this.$EL_MESSAGE("请先登录");
        this.$router.push("/login");
      }
    },
    handleSizeChange(val) {
      console.log(val);
      this.pages.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pages.current = val;
      this.getDatas();
    },
    goSignUp(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.SignUp.loading = true;
          protocolNJP.param_signUp.param.itemId = this.selectGood.hangResourcesId;
          protocolNJP.param_signUp.param.holdId = this.dialogForm.holdId;
          // protocolNJP.param_signUp.param.offerPrice = (Number(this.selectGood.startPrice)*0.3).toFixed(2)
          protocolNJP.param_signUp.param.code = this.dialogForm.code;
          protocolNJP.param_signUp.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.dialogForm.password
          );
          protocolNJP.param_signUp.param.type = this.dialogForm.type
            ? this.dialogForm.type
            : "BO"; //买履约订金摘BA：买全款摘SO：卖履约订金摘SA：卖仓单摘 求购单可选
          http.postNewJP(protocolNJP.param_signUp).then(response => {
            const { code, message, value } = response.data;
            this.resetFrom(formName);
            this.SignUp.loading = false;
            this.SignUp.isGoSignUp = false;
            this.$EL_MESSAGE(message);
            if (code == 0) {
              // 刷新报名数据
              setTimeout(() => {
                that.param_queryAuctionEnrolment();
                if (that.selectGood.hangResourcesType == "Q") {
                  this.$router.push("/sellerCenter/sales/registeredBidding");
                } else {
                  this.$router.push("/buyerCenter/purchase/registeredBidding");
                }
              }, 500);
            }
          });
        }
      });
    },
    // 查询用户报名的竞价商品
    param_queryAuctionEnrolment() {
      protocolFwd.param_queryAuctionEnrolment.param = {
        isFinish: null,
        tradeStatus: null,
        payStatus: null,
        hangResourceType: null,
        page: 0,
        size: 1000,
        sort: null,
        firmId: this.sessionInfoGetter.firmId
      };
      http.postFront(protocolFwd.param_queryAuctionEnrolment).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          console.log(value.content);
          this.$store.commit("setSelectFirmApplyCommodity", value.content);
          this.getDatas();
        } else {
          console.log(message);
        }
      });
    },
    resetFrom(formName) {
      this.SignUp.isGoSignUp = false;
      this.dialogForm = {
        code: null,
        password: null,
        holdId: null,
        type: null
      };
      // this.$refs[formName].resetFields();
    },
    tableCellClick(row, column, event) {
      console.log("tableCellClick");
      this.$store.commit("setSelectMallGood", row);
      this.$router.push({
        name: "mallProductionDetails",
        params: {
          hangResourcesId: row.hangResourcesId,
          pickResourcesId: row.pickResourcesId
        }
      });
      event.stopPropagation();
    },
    TableRowClick(row, column, cell, event) {
      if (!column.label) {
        return;
      }
      this.$store.commit("setSelectMallGood", row);
      this.$router.push({
        name: "mallProductionDetails",
        params: {
          hangResourcesId: row.hangResourcesId,
          pickResourcesId: row.pickResourcesId
        }
      });
      event.stopPropagation();
    }
  }
};
</script>
<style lang="scss" scoped>
.mall {
  padding: 15px;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 10px auto;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.mall .category {
  color: #333333;
}
.mall .categoryList {
  line-height: 45px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
}
.mall .category .categoryTitle {
  float: left;
  width: 75px;
  font-weight: 700;
}
.mall .category .categoryContent {
  margin-left: 45px;
}
.mall .category .categoryContent .item {
  margin: 0 5px;
  padding: 0 5px;
  cursor: pointer;
}
.mall .category .categoryContent .item.active {
  color: #ffffff;
  background-color: $commonThemeColor;
}
.mall .firstCategory .item {
  margin-left: 1px;
  width: 10%;
  height: 50px;
  text-align: center;
  padding: 0px 5px;
  line-height: 50px;
  cursor: pointer;
  background-color: #dcdcdc;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.mall .search {
  line-height: 45px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
}
.mall .searchItem {
  margin-right: 15px;
  cursor: pointer;
}
.mall .category .item.active {
  color: #ffffff;
  background-color: $commonThemeColor;
}

.mall .redBox {
  background: $commonThemeColor;
  color: #ffffff;
}
.mall .searchItem:hover {
  margin-right: 15px;
  background: $commonThemeColor;
  color: #ffffff;
}
.mall .activeSearchItem {
  margin-right: 15px;
  color: $commonThemeColor;
}
.mall .sort {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f2f2f2;
}
.mall .sort .sortItem {
  margin-right: 10px;
}
.mall .sort .sortItem.active {
  color: $commonThemeColor;
}
.mall .productionList .item {
  padding: 10px 0;
  line-height: 27px;
  border-bottom: 1px dashed #f2f2f2;
}
.mall .productionList .firstCloumn {
  overflow: hidden;
}
.mall .productionList .firstCloumn .proImage {
  float: left;
  width: 200px;
  height: 120px;
  background-color: #cccccc;
}
.mall .productionList .firstCloumn .proImage p {
  line-height: 120px;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}
.mall .productionList .firstCloumn .proContent {
  margin-left: 215px;
}
.mall .productionList .firstCloumn .proContent .proTitle {
  font-size: 16px;
}
.mall .productionList .firstCloumn .proContent .rushProgress {
  margin-top: 15px;
}
.mall .productionList .firstCloumn .proContent .alreadyRush {
  font-size: 15px;
  // color: $commonThemeColor;
  // font-style: italic;
  // font-weight: 700;
}
.mall .productionList .secondCloumn,
.mall .productionList .thirdCloumn,
.mall .productionList .fourthCloumn {
  text-align: center;
}
.mall .productionList .item .marketPrice {
  color: #9999a2;
}
.mall .productionList .item .rushPrice {
  color: $commonThemeColor;
  font-weight: 700;
}
.mall .productionList {
  /deep/ .cell {
    text-align: center;
  }
}
.tupian {
  position: absolute;
  margin: auto;
  right: 33px;
  top: 3px;
  width: 97px;
  height: 34px;
}
</style>
